import React from 'react';
import Container from "@material-ui/core/Container";

const QuestionContainer = (props) => {
    const { maxWidth, question, content} = props;

    const switchView = () => {

        switch(question)
        {

            case "h1":   return (<h1>{content}</h1>);
            case "h2":   return (<h2>{content}</h2>);
            case "p":    return  (<p>{content}</p>);
            default:      return (<h1>{content}</h1>);
        }
    }

    return (
        <Container maxWidth={maxWidth}>
            { switchView() }
        </Container>
    );
};


export default QuestionContainer;