import React from 'react';
import {createMuiTheme, makeStyles, MuiThemeProvider} from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

const theme = createMuiTheme({
    palette: {
        secondary: {
            main: '#000000',
        },
    },
});

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: "100%",
    },
    button: {
        margin: theme.spacing(1, 1, 0, 0),

    },
    root: {
        boxSizing: 'border-box',
        margin: '8px 0px',
        minWidth: '0px',
        border: '1px solid rgb(222, 222, 222)',
        borderRadius: '5px',
        cursor: 'pointer',
        padding: '16px',
        WebkitBoxAlign: 'center',
        alignItems: 'center',
        display: 'flex',
        secondary: {
            main: '#000000',
        },
    },
}));

const InputRadioContainer = (props) => {
    const classes = useStyles();
    const {itemInput,setInputStateHandler} = props;


    const handleRadioChange = (event) => {
        setInputStateHandler(event.target.value);

    };

    return (
        <MuiThemeProvider theme={theme}>
        <form>
            <FormControl component="fieldset" className={classes.formControl}>
                <RadioGroup aria-label="quiz" name="quiz" value={itemInput.currentValue} onChange={handleRadioChange}>
                    {itemInput.value.map((item, key) => (
                        <FormControlLabel
                            key={key}
                            value={item}
                            control={<Radio />}
                            label={item}
                            className={classes.root}
                        />
                    ))}
                </RadioGroup>

            </FormControl>
        </form>
        </MuiThemeProvider>
    );
}

export default InputRadioContainer;