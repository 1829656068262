import * as actionTypes from './actionTypes';
import {updateObject} from "../../../core/utils/reducerUtils";
const nextButtonReducerHandler = (state, action) => {
    const {chatflow} = state;
    const {currentItem} = action;

    if (currentItem.input.currentValue.length > 0) {
        return updateObject(state, {
            currentQuestion: currentItem.number + 1,
            chatflow: chatflow.map(item => item.number === currentItem.number + 1 ? ({
                ...item,
                active: true,
            }) : ({
                ...item,
                active: false,
            })),
            valid: true,
            errorMsg: '',
        });
    } else {
        return updateObject(state, {
            valid: false,
            errorMsg: chatflow[currentItem.number-1].input.type === "radio" ? 'You must select an option to continue' : 'Fields need completed to continue'
        });
    }
};

const backButtonReducerHandler = (state, action) => {
    const {chatflow} = state;
    const {currentItem} = action;


    return updateObject(state, {
        currentQuestion: currentItem.number-1,
        chatflow: chatflow.map(item => item.number === currentItem.number-1 ? ({...item, active: true,}) : ({...item,active: false,})),
    });
};

const SetInputValueReducerHandler = (state, action) => {
    const {chatflow} = state;
    return updateObject(state,{
        chatflow: chatflow.map(item => item.active ? ({
            ...item,
            input: {
                ...item.input,
                currentValue: action.value,
            }
        }) : ({
            ...item,
        })),
    });
};


const reducer = (state, action) => {
      switch (action.type) {
          case actionTypes.NEXT_BUTTON: return nextButtonReducerHandler(state, action);
          case actionTypes.BACK_BUTTON: return backButtonReducerHandler(state, action);
          case actionTypes.SET_INPUT_VALUE: return SetInputValueReducerHandler(state, action);
          default: return {...state};
      }
};

export default reducer;