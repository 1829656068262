import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "space-between",
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));


const StepButtonContainer = (props) => {
    const classes = useStyles();

    const {backButtonHandler, nextButtonHandler, currentQuestion, itemsNumber} = props;
    return (
        <div className={classes.root}>
            {currentQuestion > 1 ? (
                <Button variant="contained" color="secondary" onClick={() => backButtonHandler()}>
                    Back
                </Button>
            ) : null}

            {currentQuestion < itemsNumber ? (
                <Button variant="contained" color="primary" onClick={() => nextButtonHandler()}>
                    Next
                </Button>
            ) : null}


        </div>
    );
}

export default StepButtonContainer;