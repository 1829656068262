import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';

const useStyles = makeStyles({
    root: {
        maxWidth: "100%",
        flexGrow: 1,
        '& .MuiMobileStepper-progress': {
            width: "100%",
        },
    },
});


const ProgressComponent = (props) => {

    const { activeStep, totalSteps} = props;
    const classes = useStyles();
    return (
        <MobileStepper
            variant="progress"
            steps={totalSteps}
            position="static"
            activeStep={activeStep-1}
            className={classes.root}
        />
    );
}

export default ProgressComponent;