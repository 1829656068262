import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles({
    root: {
        marginBottom: "10%",
    },
});




const InputTextContainer = (props) => {
    const {setInputStateHandler, value} = props;
    const classes = useStyles();
    const setValueInput = (event) => {
        setInputStateHandler(event.target.value);
    };

    return (
        <form className={classes.root} noValidate autoComplete="off">
            <TextField value={value} id="outlined-basic" variant="outlined" placeholder="test" onChange={setValueInput}/>
        </form>
    );
};

export default InputTextContainer;