const initialStateMainKomparatore = {
    currentQuestion: 1,
    valid: true,
    errorMsg: '',
    chatflow: [
        {
            number: 1,
            active: true,
            title: {
                content: "Title 1"
            },
            question: {
                type: "h2",
                content: "This is a question???1",
            },
            input: {
                type: "radio",
                value: ["testValue1","testValue2","testValue3"],
                currentValue: '',
            },
        },
        {
            number: 2,
            active: false,
            title: {
                content: "Title 2"
            },
            question: {
                type: "",
                content: "This is a question???2",
            },
            input: {
                type: "radio",
                value: ["testValue11","testValue22","testValue33","testValue44"],
                currentValue: '',
            },
        },
        {
            number: 3,
            active: false,
            title: {
                content: "Title 3"
            },
            question: {
                type: "p",
                content: "This is a question???",
            },
            input: {
                type: "radio",
                value: ["testValue11","testValue22","testValue33","testValue44"],
                currentValue: '',
            },
        },
        {
            number: 4,
            active: false,
            title: {
                content: "Title 4"
            },
            question: {
                type: "h2",
                content: "This is a question???",
            },
            input: {
                type: "text",
                currentValue: '',
            },
        },
        {
            number: 5,
            active: false,
            title: {
                content: "Title 5"
            },
            question: {
                type: "h2",
                content: "🚀 👉 Vuoi Risparmiare sulla Tariffa Luce e Gas fino a 300€ all'anno? 💰 Per calcolare la miglior promo dedicata a te ho bisogni di sapere che gestore hai attualmente",
            },
            input: {
                type: "radio",
                value: ["testValue1","testValue2","testValue3"],
                currentValue: '',
            },
        },
        {
            number: 6,
            active: false,
            title: {
                content: "Title 6"
            },
            question: {
                type: "h2",
                content: "This is a question???",
            },
            input: {
                type: "radio",
                value: ["testValue1","testValue2","testValue3"],
                currentValue: '',
            },
        },
        {
            number: 7,
            active: false,
            title: {
                content: "Title 7"
            },
            question: {
                type: "h2",
                content: "This is a question???",
            },
            input: {
                type: "radio",
                value: ["testValue1","testValue2","testValue3"],
                currentValue: '',
            },
        },
        {
            number: 8,
            active: false,
            title: {
                content: "Title 8"
            },
            question: {
                type: "h2",
                content: "This is a question???",
            },
            input: {
                type: "radio",
                value: ["testValue1","testValue2","testValue3"],
                currentValue: '',
            },
        },
        {
            number: 9,
            active: false,
            title: {
                content: "Title 9"
            },
            question: {
                type: "h2",
                content: "This is a question???",
            },
            input: {
                type: "radio",
                value: ["testValue1","testValue2","testValue3"],
                currentValue: '',
            },
        },
    ]
};

export default initialStateMainKomparatore;