import React, {Fragment, useReducer} from 'react';
import CssBaseline from "@material-ui/core/CssBaseline";
import PrincipalContainer from "../../core/components/containers/PrincipalContainer";
import ProgressContainer from "../../core/components/UI/ProgressBar/ProgressComponent";
import TitleContainer from "./components/TitleContainer";
import QuestionContainer from "./components/QuestionContainer";
import StepButtonContainer from "./components/StepButtonContainer";
import FormContainer from "../../core/components/containers/FormContainer";
import InputRadioContainer from "../../core/components/UI/Inputs/InputRadioContainer";
import InputTextContainer from "../../core/components/UI/Inputs/InputTextContainer";
import initialState from "./store/initialState";
import FormHelperText from "@material-ui/core/FormHelperText";
import {nextButtonHandler, backButtonHandler, setInputValueHandler} from "./store/actions";
import reducerMainKomparatore from "./store/reducer";

const MainKomparatore = () => {
    const [state, dispatch] = useReducer(reducerMainKomparatore, initialState);
    const {chatflow, currentQuestion, valid, errorMsg} = state;
    const chatflow_length = chatflow.length;

    const switchInput = (item) => {

        switch(item.input.type){
            case "radio": return (
                <InputRadioContainer
                    nextButtonHandler={() => nextButtonHandler(valid)}
                    itemInput={item.input}
                    currentQuestion={currentQuestion}
                    setInputStateHandler={(value) => setInputValueHandler(dispatch,value)}
                />
            );
            case "text": return (
                <InputTextContainer value={item.input.currentValue} setInputStateHandler={(value) => setInputValueHandler(dispatch,value)}/>
            );
            default: return null;
        }
    };

    console.log(state);
    return (
        <Fragment>
            <CssBaseline/>
            <PrincipalContainer maxWidth="sm">
                <FormContainer>
                    <ProgressContainer activeStep={currentQuestion} totalSteps={chatflow_length}/>
                    {chatflow.map((item, key) => item.active === true ? (
                        <Fragment key={key}>
                            <TitleContainer content={item.title.content} number={item.number} />
                            <QuestionContainer question={item.question.type} content={item.question.content}/>
                            {switchInput(item)}
                            <FormHelperText error={!valid}>{errorMsg}</FormHelperText>
                            <StepButtonContainer
                                backButtonHandler={() => backButtonHandler(dispatch, item)}
                                nextButtonHandler={() => nextButtonHandler(dispatch, item)}
                                currentQuestion={item.number}
                                itemsNumber={chatflow_length}
                            />
                        </Fragment>
                    ) : null)}
                </FormContainer>
            </PrincipalContainer>
        </Fragment>
    );
}

export default MainKomparatore;