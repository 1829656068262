import React from 'react';
import Container from '@material-ui/core/Container';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    root: {
        '& .MuiContainer-root': {
            background: "#ffffff",
            boxSizing: "border-box",
            margin: "0px",
            minWidth: "0px",
            padding: "32px",
        },
    },
});

const PrincipalContainer = (props) => {
    const {children, maxWidth} = props;
    const classes = useStyles();

    return (
        <Container maxWidth={maxWidth} className={classes.root}>
            {children}
        </Container>
    );
};

export default PrincipalContainer;