import React from 'react';
import Container from '@material-ui/core/Container';


const FormContainer = (props) => {
    const {children, maxWidth} = props;


    return (
        <Container maxWidth={maxWidth} >
            {children}
        </Container>
    );
};

export default FormContainer;