import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    root: {

            boxSizing: "border-box",
            margin: "0px",
            minWidth: "0px",
            padding: "4px 16px",
            color: "white",
            backgroundColor: "#0fa3f0",
            borderRadius: "5px",
            paddingRight: "25px",

    },
});


const TitleContainer = (props) => {
    const {content, number} = props;
    const classes = useStyles();

    return (
        <Grid container spacing={0}>
            <Grid item xs={1} sm={1}>
                <Box bgcolor="primary.main" color="primary.contrastText" className={classes.root} style={{textAlign: 'center', marginTop: '25px'}} p={1}>{number}.</Box>
            </Grid>
            <Grid item xs={11} sm={11}>
            <h1 style={{ margin: "20px 0px 0px 25px", minWidth: "0px" }}>{content}</h1>
            </Grid>
        </Grid>
    );
}

export default TitleContainer;